import React, { useState, useEffect, useRef, useCallback } from "react";
import "./CertificateEditor2212.css";

const CertificateEditor2212 = () => {
  const [name, setName] = useState("");
  const [avatarSrc, setAvatarSrc] = useState(
    "./images/avatar-2212.jpg"
  );
  const [backgroundSrc] = useState(
    "./images/background-2212.png"
  );
  const [zoom, setZoom] = useState(2.2);
  const [xPos, setXPos] = useState(26);
  const [yPos, setYPos] = useState(46);
  const [expectWidth, setExpectWidth] = useState(80);

  const canvasRef = useRef(null);
  const imgBackgroundRef = useRef(null);
  const imgAvatarRef = useRef(null);

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
      img.src = src;
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      try {
        imgBackgroundRef.current = await loadImage(backgroundSrc);
        imgAvatarRef.current = await loadImage(avatarSrc);
        updateCertificate();
      } catch (error) {
        console.error("Lỗi khi tải hình ảnh:", error);
      }
    };
    loadImages();
  }, [backgroundSrc, avatarSrc]);

  const updateCertificate = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imgBackgroundRef.current || !imgAvatarRef.current) {
      return;
    }
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";

    const sliderXVal = xPos / 100;
    const sliderYVal = (100 - yPos) / 100;
    const sliderZoomVal = zoom / 20 + 0.2;

    const xPosCanvas = canvas.width * sliderXVal;
    const yPosCanvas = canvas.height * sliderYVal;
    const scaleFactor =
      (sliderZoomVal * ((expectWidth / 100) * canvas.width)) /
      imgAvatarRef.current.width;

    const dispWidth = imgAvatarRef.current.width * scaleFactor;
    const dispHeight = imgAvatarRef.current.height * scaleFactor;

    ctx.drawImage(
      imgAvatarRef.current,
      xPosCanvas - dispWidth / 2,
      yPosCanvas - dispHeight / 2,
      imgAvatarRef.current.width * scaleFactor,
      imgAvatarRef.current.height * scaleFactor
    );

    ctx.drawImage(imgBackgroundRef.current, 0, 0, canvas.width, canvas.height);

    ctx.textAlign = "center";
    ctx.fillStyle = "white";
    ctx.font = "42px SVNGilroyRegular";
    ctx.fillText(name, 365, 158);
  }, [xPos, yPos, zoom, expectWidth, name]);

  const requestUpdate = useCallback(() => {
    requestAnimationFrame(updateCertificate);
  }, [updateCertificate]);

  useEffect(() => {
    requestUpdate();
  }, [xPos, yPos, zoom, expectWidth, requestUpdate]);

  const handleNameChange = (e) => {
    setName(e.target.value);
    updateCertificate();
  };

  const handleFileChange = (e) => {
    const input = e.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setAvatarSrc(e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
    }
  };

  const handleDownloadClick = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas không tồn tại");
      return;
    }
    const image = canvas.toDataURL();
    const tmpLink = document.createElement("a");
    tmpLink.download = "certificate.png";
    tmpLink.href = image;
    document.body.appendChild(tmpLink);
    tmpLink.click();
    document.body.removeChild(tmpLink);
  };

  return (
    <main>
      <div className="container">
        <div className="input-row justify-center">
          <div className="row-name flex">
            <input
              className="full_name"
              id="id_input_name"
              type="text"
              name="fname"
              placeholder="Nhập tên..."
              value={name}
              onChange={handleNameChange}
              autoFocus
            />
            <input
              type="file"
              id="id_file_up"
              accept="image/*"
              onChange={handleFileChange}
              hidden
            />
            <label className="upload_btn" htmlFor="id_file_up" id="id_upload">
              <i className="fa fa-cloud-upload" aria-hidden="true"></i>&nbsp;Upload avatar
            </label>
          </div>
        </div>
        <div className="canvas-area">
          <div className="flex canvas-row justify-center">
            <div className="left flex flex-column justify-center align-center">
              <input
                type="range"
                min="1"
                max="20"
                step="1"
                value={zoom}
                className="vertical_silder"
                id="id_zoom"
                onChange={(e) => setZoom(parseInt(e.target.value))}
                aria-valuemin="1"
                aria-valuemax="20"
                aria-valuenow={zoom}
                aria-label={`Zoom: ${zoom}`}
              />
              <label htmlFor="id_zoom" id="label-zoom">
                <i className="fa fa-search-plus" aria-hidden="true"></i>&nbsp;Zoom
              </label>
            </div>

            <canvas
              id="id_main_canvas"
              ref={canvasRef}
              width="1280"
              height="718"
            ></canvas>

            <div className="right flex flex-column justify-center align-center">
              <input
                type="range"
                min="1"
                max="100"
                step="1"
                value={yPos}
                className="vertical_silder"
                id="id_height_pos"
                onChange={(e) => setYPos(parseInt(e.target.value))}
                aria-valuemin="1"
                aria-valuemax="100"
                aria-valuenow={yPos}
                aria-label={`Chiều dọc: ${yPos}`}
              />
              <label htmlFor="id_height_pos" id="label-height">
                <i className="fa fa-arrows-v" aria-hidden="true"></i>&nbsp;Chiều dọc
              </label>
            </div>
          </div>
          <div className="horizontal flex flex-column justify-center align-center">
            <input
              type="range"
              min="1"
              max="100"
              step="1"
              value={xPos}
              className="slider"
              id="id_width_pos"
              onChange={(e) => setXPos(parseInt(e.target.value))}
              aria-valuemin="1"
              aria-valuemax="100"
              aria-valuenow={xPos}
              aria-label={`Chiều ngang: ${xPos}`}
            />
            <label htmlFor="id_width_pos" id="label-width">
              <i className="fa fa-arrows-h" aria-hidden="true"></i>&nbsp;Chiều ngang
            </label>
          </div>
        </div>
        <div className="download-btn flex justify-center">
          <button id="id_download" hidden onClick={handleDownloadClick}>
            Download
          </button>
          <label className="download_btn" htmlFor="id_download" id="download">
            <i className="fa fa-download"></i>&nbsp;Download
          </label>
        </div>
      </div>
    </main>
  );
};

export default CertificateEditor2212;
